*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins' !important;
} 

/* body{
  background: #FFAFBD !important;
  background: -webkit-linear-gradient(to left, #ffc3a0, #FFAFBD) !important;
  background: linear-gradient(to left, #ffc3a0, #FFAFBD) !important;
} */

a{
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.customDatePicker{
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  width: 120px;
  border-radius: 5px;
  padding: 9px;
  transition: border-color 0.3s ease;
  cursor: pointer;
  /* margin-right: 8px; */
}

.customDatePicker:focus{
  outline: none;
  border: 2px solid #FC2947 !important;
}

.customDatePicker1{
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  width: 100% !important;
  border-radius: 5px;
  padding: 17px;
  transition: border-color 0.3s ease;
  cursor: pointer;
  /* margin-right: 8px; */
}

.customDatePicker1:focus{
  outline: none;
  border: 2px solid #FC2947 !important;
}

.customDatePickerError{
  border: 2px solid #FC2947 !important;
}

.gradiant1{
  background-image: radial-gradient( circle farthest-corner at 3.1% 6.8%,  rgba(199,0,59,1) 0%, rgba(255,88,53,1) 97.7% );
}  

.gradiant2{
  background-image: radial-gradient( circle farthest-corner at 2.9% 7.7%,  rgba(164,14,176,1) 0%, rgba(254,101,101,1) 90% );
}  

.gradiant3{
  background-image: linear-gradient( 109.6deg,  rgba(24,138,141,1) 11.2%, rgba(96,221,142,1) 91.1% );
}

.gradiant4{
  background-image: radial-gradient( circle farthest-corner at 7.2% 13.6%,  rgba(37,249,245,1) 0%, rgba(8,70,218,1) 90% );
}

.custom-dialog-paper {
  overflow-y: inherit !important;
}

.react-datepicker-wrapper{
  width: 100% !important;
}
